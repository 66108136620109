import { Suspense } from "react";
import { useLoaderData, useOutlet, Await } from "react-router-dom";
import AuthProvider from "../reducers/auth";
import { Spin } from "antd";

export const AuthLayout = () => {
  const outlet = useOutlet();

  const { userPromise } = useLoaderData() as any;

  return (
    <Suspense
      fallback={
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin spinning />
        </div>
      }
    >
      <Await
        resolve={userPromise}
        errorElement={<div />}
        children={(user) => (
          <AuthProvider userData={user}>{outlet}</AuthProvider>
        )}
      />
    </Suspense>
  );
};
