import { User, UserInfo, signInWithEmailAndPassword } from "firebase/auth";
import React, { useContext, useMemo } from "react";
import { ELocalStorage, useLocalStorage } from "../../hook/useLocalStorage";
import { auth, db } from "../../services/firebase";
import { IRequestLogin } from "./authTypes";
import AuthContext from "./context";
import { doc, getDoc } from "firebase/firestore";

const AuthProvider: React.FC<{ userData: User & {listDoc?: string[]} | undefined }> = ({
  children,
  userData,
}) => {
  const [user, setUser] = useLocalStorage(ELocalStorage.USER, userData);
  const [token, setToken] = useLocalStorage(ELocalStorage.TOKEN, "");

  const onLogin = async ({ email, password }: IRequestLogin) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      var user = result.user;
      const credential = await user.getIdTokenResult();
      setToken(credential.token);
      localStorage.setItem(ELocalStorage.USERId, user.uid);

      const listDoc = (await getDoc(doc(db, `Users/${user.uid}`))).get(
        "doc_ids"
      );


      onSetUser({ ...user.providerData?.[0],listDoc });
      return result;
    } catch (error) {
      throw error;
    }
  };
  // const onLogout = async () => {
  //   // await auth.signOut();
  // };
  const onSetUser = (user: UserInfo & {
    listDoc:string[]
  }) => {
    setUser(user);
  };

  const value = useMemo(
    () => ({
      user,
      onLogin,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
export const useAuth = () => {
  return useContext(AuthContext);
};
