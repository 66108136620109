import { App, Button, Form, Input } from "antd";
import React, { useCallback, useState } from "react";
import { Logo } from "../../assets/svgs";
import AuthContext from "../../reducers/auth/context";
import { IRequestLogin } from "../../reducers/auth/authTypes";

const Login = () => {
  const { notification } = App.useApp();
  const { onLogin } = React.useContext(AuthContext);

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = useCallback(
    async ({ email, password }: IRequestLogin) => {
      try {
        setLoading(true);
        await onLogin({ email, password });
      } catch (error) {
        notification.error({
          message: "Invalid Credentials",
          description:
            "Ahh, seems like you entered the wrong email or password. Please try again",
        });
      } finally {
        setLoading(false);
      }
    },
    [onLogin, notification]
  );

  return (
    <div className='screen-wrapper'>
      <div style={{ paddingTop: 100, width: "100%" }}>
        <div style={{ textAlign: "center" }}>
          <Logo />
        </div>
        <div style={{ marginTop: 26 }} />
        <Form
          style={{ width: "100%", maxWidth: 242, margin: "0 auto" }}
          layout='vertical'
          initialValues={{ email: "", password: "" }}
          onFinish={onSubmit}
        >
          <Form.Item
            label='Email'
            name='email'
            rules={[
              {
                required: true,
                message: "Please enter your email",
              },
            ]}
          >
            <Input type='email' size='large' autoFocus />
          </Form.Item>
          <Form.Item
            label='Password'
            name='password'
            rules={[
              {
                required: true,
                message: "Please enter your password",
              },
            ]}
          >
            <Input type='password' size='large' />
          </Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            loading={loading}
            size='large'
            style={{ width: "100%", marginTop: 14, fontWeight: 600 }}
          >
            LOGIN
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Login;
