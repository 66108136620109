import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { RedocStandalone } from "redoc";
import Header from "../../components/Header";
import { ELocalStorage } from "../../hook/useLocalStorage";
import { db } from "../../services/firebase";
import { isJsonString } from "../../utils";
import hljs from "highlight.js";
import "highlight.js/styles/github-dark.css";
import API from "../../external.json";

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    hljs.highlightAll();
  });

  /* Auto redirect when no has pathname */
  useEffect(() => {
    if (location.pathname !== "/") return;
    const userDocs = localStorage.getItem(ELocalStorage.USERDocs);
    navigate(`/${userDocs?.split(",")[0]}`);
  }, [location.pathname]);

  const [docData, setDocData] = useState("");

  const [data, setData] = useState({
    title: "AmpUp",
    description: ""
  });

  useEffect(() => {
    getDocFromUser();
  }, []);

  const getDocName = () => {
    const docName = location.pathname.split("/");
    if (docName.length > 1) {
      return docName[docName.length - 1];
    }
    return "";
  };
  const getDocFromUser = async () => {
    try {
      const userId = localStorage.getItem(ELocalStorage.USERId);
      const docName = getDocName();

      if (!userId) {
        localStorage.clear();
        return;
      }
      const userDocs = (await getDoc(doc(db, `Users`, userId as string))).get(
        "doc_ids"
      );

      const queryDoc = query(
        collection(db, "Documents"),
        where("doc_id", "==", !docName ? userDocs[0] : docName)
      );
      const snapshotDocs = await getDocs(queryDoc);
      snapshotDocs.docs.forEach((res) => {
        if (res.data()) {
          if (isJsonString(res.data()?.doc_data || "")) {
            setDocData(JSON.parse(res.data()?.doc_data));
          }
        }
      });
    } catch (error) {
      console.log("--error", error);
    }
  };

  return (
    <div className='App'>
      <Helmet>
        <title>{data.title || "AmpUp Apis Document"}</title>
        <meta name='description' content={data.description || ""} />
      </Helmet>
      <Header />
      <div style={{ paddingTop: 20 }} />
      <RedocStandalone
        specUrl={docData}
        options={{
          disableSearch: false,
          nativeScrollbars: true,
          hideHostname: true,
          theme: {
            logo: { maxWidth: "100", maxHeight: "100", gutter: "100" },
            colors: {
              primary: {
                main: "#00d89e",
                light: "rgba(246, 20, 63, 0.42)"
              },
              success: {
                main: "rgba(28, 184, 65, 1)",
                light: "#00d89e",
                dark: "#083312",
                contrastText: "#000"
              },
              text: {
                primary: "#fff",
                secondary: "#a6a6a6"
              },
              http: {
                get: "#6bbd5b",
                post: "#248fb2",
                put: "#9b708b",
                options: "#d3ca12",
                patch: "#e09d43",
                delete: "#e27a7a",
                basic: "#999",
                link: "#31bbb6",
                head: "#c167e4"
              }
            },
            typography: {
              fontFamily: "Montserrat",
              optimizeSpeed: true,
              smoothing: "antialiased",
              headings: {
                fontWeight: "600",
                lineHeight: "1em"
              },
              code: {
                fontWeight: "600",
                color: "#00d89e",
                wrap: true
              },
              links: {
                color: "#00d89e",
                visited: "#00d89e",
                hover: "#00d89e"
              }
            },
            codeBlock: {
              backgroundColor: "rgb(33, 33, 33)"
            },
            sidebar: {
              backgroundColor: "rgb(38, 38, 38)",
              textColor: "white",
              activeTextColor: "#00d89e"
            },
            rightPanel: {
              backgroundColor: "rgb(38, 38, 38)",
              textColor: "white"
            }
          }
        }}
        onLoaded={(error) => {
          if (!error) {
            console.log("Yay!");
          }
        }}
      />
    </div>
  );
};

export default Home;
