import { initializeApp } from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/messaging";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
const apiKey = process.env.REACT_APP_API_KEY;
const authDomain = process.env.REACT_APP_AUTH_DOMAIN;
const projectId = process.env.REACT_APP_PROJECT_ID;
const storageBucket = process.env.REACT_APP_STORAGE_BUCKET;
const messagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID;
const appId = process.env.REACT_APP_APP_ID;
const measurementId = process.env.REACT_APP_MEASUREMENT_ID;

const fbConfig = {
  // apiKey: process.env.FB_API_KEY,
  // authDomain: process.env.FB_AUTH_DOMAIN,
  // databaseURL: process.env.FB_DATABASE_URL,
  // projectId: process.env.FB_PROJECT_ID,
  // storageBucket: process.env.FB_STORAGE_BUCKET,
  // messagingSenderId: process.env.FB_MESSAGING_SENDER_ID,
  // appId: process.env.FB_APP_ID,
  // measurementId: process.env.FB_MEASUREMENT_ID,
  apiKey,
  authDomain,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId
};

const firebase = initializeApp(fbConfig);
const db = getFirestore(firebase);
const auth = getAuth(firebase);
const storage = getStorage(firebase);

getAnalytics(firebase);

export default firebase;

export { auth, db, storage };
