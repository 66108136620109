import { createContext } from "react";
import { TInitialState, TUser } from "./authTypes";
import { IRequestLogin } from "./authTypes";
import { UserCredential } from "firebase/auth";

export const actions = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
};

export const initialState: TInitialState = {
  state: {
    user: null,
  },
  dispatch: () => null,
};

const AuthContext = createContext<{
  user: TUser | null;
  onLogin: (payload: IRequestLogin) => Promise<UserCredential | undefined>;
}>({
  user: null,
  onLogin: async () => undefined,
});

export default AuthContext;
