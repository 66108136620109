import { FC, useContext } from "react";
import { Navigate, useOutlet } from "react-router-dom";
import AuthContext from "../reducers/auth/context";

const RequireAuth: FC<{}> = ({ children }) => {
  let { user } = useContext(AuthContext);
  const outlet = useOutlet();

  if (!user) {
    return (<Navigate to='/login' />) as any;
  }

  return outlet;
};

export default RequireAuth;
