import React from "react";
import ReactDOM from "react-dom";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
import reportWebVitals from "./reportWebVitals";

import { App, ConfigProvider, theme } from "antd";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { AuthLayout } from "./components/AuthLayout";
import { HomeLayout } from "./components/HomeLayout";
import RequireAuth from "./components/RequireAuth";
import { ELocalStorage } from "./hook/useLocalStorage";
import { auth, db } from "./services/firebase";
import CreateAccount from "./pages/Account/CreateAccount";

const getUserData = async () => {
  const token = window.localStorage.getItem(ELocalStorage.TOKEN);
  try {
    if (typeof token === "string") {
      return onAuthStateChanged(auth, async (user) => {
        try {
          if (user) {
            const listDoc = (await getDoc(doc(db, `Users/${user.uid}`))).get(
              "doc_ids"
            );
            localStorage.setItem(ELocalStorage.USERDocs, listDoc);

            return {...user, listDoc};
          } else {
            return "";
          }
        } catch (error) {
          console.log("----Get user", error);
        }
      });
    }
    return "";
  } catch (error) {
    return "";
  }
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={() => defer({ userPromise: getUserData() })}
    >
      <Route element={<HomeLayout />}>
        <Route path='/login' element={<Login />} />
      </Route>

      <Route element={<RequireAuth />}>
        <Route path='/create-account' element={<CreateAccount />} />
        <Route path='/*' element={<Home />} />
      </Route>
    </Route>
  )
);

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: "#00d89e",
          colorText: "#fff",
          colorSuccess: "green",
          colorError: "red",
          colorInfo: "blue",
          colorWarning: "yellow",
        },
      }}
    >
      <App>
        <RouterProvider router={router} />
      </App>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
