import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../reducers/auth";

export const HomeLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();
  
  if (user) {
    return <Navigate to={user?.listDoc?.[0] || '' } replace />;
  }

  return <div>{outlet}</div>;
};
