import { App, Button, Form, Input, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Logo } from "../../assets/svgs";
import { auth, db } from "../../services/firebase";

type TForm = {
  email: string;
  password: string;
  docId: string;
};
const CreateAccount = () => {
  const { notification } = App.useApp();

  const [loading, setLoading] = useState(false);
  const [form] = useForm<TForm>();
  const [listDoc, setListDoc] = useState<
    { doc_id: string; doc_url: string; doc_name: string }[]
  >([]);

  useEffect(() => {
    getListDoc();
  }, []);

  const getListDoc = async () => {
    try {
      const snapshotDocs = await getDocs(collection(db, "Documents"));
      setListDoc(snapshotDocs.docs.map((res) => res.data()) as any);
    } catch (error) {}
  };

  const onSubmit = async (values: TForm) => {
    try {
      setLoading(true);
      const responseCreateAccount = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      const user = responseCreateAccount.user;
      await setDoc(doc(db, `Users`, user.uid), {
        doc_ids: values.docId,
      });
      notification.success({
        message: "Success",
        description: `Added ${user.email} successfully!`,
      });
    } catch (error) {
      console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };

  /* Render */
  return (
    <div className='screen-wrapper'>
      <div style={{ paddingTop: 100, width: "100%" }}>
        <div style={{ textAlign: "center" }}>
          <Logo />
        </div>
        <div style={{ marginTop: 26 }} />
        <Form
          form={form}
          style={{ width: "100%", maxWidth: 242, margin: "0 auto" }}
          layout='vertical'
          initialValues={{ email: "", password: "" }}
          onFinish={onSubmit}
        >
          <Form.Item
            label='Email'
            name='email'
            rules={[
              {
                required: true,
                message: "Please enter your email",
              },
            ]}
          >
            <Input type='email' size='large' autoFocus />
          </Form.Item>
          <Form.Item
            label='Documents'
            name='docId'
            rules={[
              {
                required: true,
                message: "Select the document",
              },
            ]}
          >
            <Select
              mode='multiple'
              size='large'
              options={listDoc.map((doc) => ({
                label: doc.doc_name,
                value: doc.doc_id,
              }))}
            />
          </Form.Item>
          <Form.Item
            label='Password'
            name='password'
            rules={[
              {
                required: true,
                message: "Please enter your password",
              },
            ]}
          >
            <Input type='password' size='large' />
          </Form.Item>

          <Button
            loading={loading}
            type='primary'
            htmlType='submit'
            size='large'
            style={{ width: "100%", marginTop: 14, fontWeight: 600 }}
          >
            SIGNUP
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default CreateAccount;
