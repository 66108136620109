import { Button, Dropdown, MenuProps } from "antd";
import { signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IcSettings } from "../assets/svgs";
import { ELocalStorage } from "../hook/useLocalStorage";
import { auth, db } from "../services/firebase";

const Header = () => {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState("");

  const handleLogout = async () => {
    try {
      signOut(auth);
      localStorage.clear();
      navigate("/login");
      navigate(0);
    } catch (error) {
      console.log("---err");
    }
  };
  const items: MenuProps["items"] = [
    {
      label: <span onClick={handleLogout}>Logout</span>,
      key: "3",
    },
  ];

  useEffect(() => {
    getUserRole();
  }, []);
  const getUserRole = async () => {
    const userId = localStorage.getItem(ELocalStorage.USERId);
    if (!userId) return;

    try {
      const userRole = (await getDoc(doc(db, `Users`, userId as string))).get(
        "role"
      );
      setUserRole(userRole);

      return userRole;
    } catch (error) {}
  };
  const listItemDropdown = useMemo(() => {
    try {
      if (userRole === "admin") {
        items.unshift(
          {
            label: (
              <span
                onClick={() => {
                  navigate("/create-account");
                }}
              >
                Add user
              </span>
            ),
            key: "0",
          },
          {
            type: "divider",
          }
        );
      }

      return items;
    } catch (error) {}
  }, [userRole]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "end",
        padding: "10px 20px",
        position: "fixed",
        top: 0,
        left: "260px",
        right: 0,
        zIndex: 99,
        background: "rgb(33, 33, 33)",
      }}
    >
      <Dropdown menu={{ items: listItemDropdown }} trigger={["hover"]}>
        <Button type='link' icon={<IcSettings />} />
      </Dropdown>
    </div>
  );
};

export default Header;
