export const Logo = () => {
  return (
    <svg
      width='179'
      height='40'
      viewBox='0 0 179 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M151.792 0.00371964C151.152 0.00371964 150.538 0.258056 150.085 0.710777C149.633 1.1635 149.378 1.77752 149.378 2.41776V18.6837C149.383 19.6563 149.196 20.6202 148.828 21.5205C148.46 22.4208 147.919 23.2398 147.234 23.9308C146.55 24.6217 145.736 25.1712 144.84 25.5476C143.943 25.9241 142.981 26.1202 142.009 26.1249C141.037 26.1295 140.073 25.9425 139.173 25.5747C138.273 25.2068 137.454 24.6652 136.763 23.9807C136.072 23.2963 135.523 22.4825 135.146 21.5858C134.77 20.6891 134.574 19.727 134.569 18.7544V2.41776C134.569 1.77653 134.314 1.16157 133.861 0.708147C133.408 0.254728 132.793 0 132.152 0C131.511 0 130.896 0.254728 130.442 0.708147C129.989 1.16157 129.734 1.77653 129.734 2.41776V18.0105C129.71 18.234 129.7 18.4589 129.705 18.6837C129.705 25.5948 135.198 31.2226 141.951 31.2226C148.705 31.2226 154.202 25.5948 154.202 18.6837V2.41776C154.201 1.77846 153.947 1.16558 153.495 0.713174C153.043 0.260772 152.431 0.00568596 151.792 0.00371964Z'
        fill='#00D89E'
      />
      <path
        d='M68.2039 9.18373C67.627 9.18432 67.0719 9.40396 66.6508 9.79823C66.2296 10.1925 65.9739 10.732 65.9353 11.3076C64.1112 9.93769 61.894 9.19283 59.6129 9.18373C53.6178 9.18373 48.7384 14.1792 48.7384 20.3166C48.7384 26.454 53.6178 31.416 59.6129 31.416C61.2968 31.445 62.9604 31.0457 64.4476 30.2555C64.991 29.9466 65.4849 29.5579 65.9129 29.1024H65.9315C65.9649 29.6953 66.2289 30.2517 66.6672 30.6523C67.1055 31.053 67.6831 31.2662 68.2766 31.2462C68.87 31.2263 69.432 30.9749 69.8425 30.5457C70.2529 30.1165 70.479 29.5437 70.4725 28.9499V11.4676C70.4735 10.8641 70.2353 10.2849 69.81 9.85682C69.3848 9.42873 68.8072 9.18669 68.2039 9.18373ZM65.5559 22.4963C65.1178 23.7376 64.3032 24.8111 63.2259 25.5673C62.1485 26.3234 60.8621 26.7244 59.546 26.7144C58.2937 26.7163 57.0682 26.3516 56.0206 25.6653C54.9731 24.979 54.1493 24.001 53.6508 22.852C53.1523 21.703 53.001 20.4332 53.2154 19.1992C53.4299 17.9652 54.0008 16.8209 54.8577 15.9076C55.7146 14.9942 56.82 14.3516 58.0377 14.0591C59.2554 13.7666 60.532 13.8369 61.7102 14.2614C62.8884 14.6859 63.9166 15.446 64.6681 16.4479C65.4195 17.4499 65.8613 18.6499 65.939 19.9C65.9672 20.037 65.9797 20.1768 65.9762 20.3166C65.9897 21.0645 65.8465 21.807 65.5559 22.4963Z'
        fill='#00D89E'
      />
      <path
        d='M116.777 8.99479C114.585 8.99129 112.446 9.6613 110.648 10.9141C110.479 10.3575 110.116 9.87997 109.625 9.56866C109.134 9.25734 108.547 9.13296 107.972 9.21818C107.397 9.3034 106.871 9.59255 106.491 10.0329C106.111 10.4733 105.902 11.0355 105.902 11.6171V37.5728C105.902 38.214 106.157 38.829 106.61 39.2824C107.064 39.7358 107.679 39.9905 108.32 39.9905C108.961 39.9905 109.576 39.7358 110.029 39.2824C110.482 38.829 110.737 38.214 110.737 37.5728V29.4156L111.912 30.0405C113.414 30.8234 115.083 31.2294 116.777 31.2233C122.753 31.2233 127.618 26.2316 127.618 20.0942C127.618 13.9568 122.764 8.99479 116.777 8.99479ZM116.755 26.4138C115.475 26.4149 114.224 26.0329 113.164 25.317C112.103 24.601 111.282 23.5839 110.804 22.3966C110.481 21.645 110.321 20.8339 110.332 20.0161C110.327 19.8565 110.34 19.6969 110.369 19.54C110.461 18.2975 110.913 17.1089 111.671 16.1198C112.428 15.1307 113.458 14.3842 114.633 13.9718C115.809 13.5594 117.079 13.499 118.288 13.798C119.497 14.097 120.593 14.7424 121.441 15.6551C122.289 16.5678 122.852 17.7081 123.061 18.9363C123.271 20.1644 123.117 21.4269 122.62 22.5691C122.122 23.7113 121.302 24.6834 120.26 25.3664C119.219 26.0493 118 26.4133 116.755 26.4138Z'
        fill='#00D89E'
      />
      <path
        d='M167.746 8.99479C165.555 8.99248 163.416 9.66238 161.617 10.9141C161.449 10.3575 161.086 9.87997 160.595 9.56865C160.103 9.25734 159.517 9.13295 158.941 9.21817C158.366 9.30339 157.841 9.59254 157.461 10.0329C157.081 10.4733 156.872 11.0355 156.872 11.6171V37.5728C156.872 38.214 157.127 38.829 157.58 39.2824C158.033 39.7358 158.648 39.9905 159.289 39.9905C159.93 39.9905 160.545 39.7358 160.999 39.2824C161.452 38.829 161.707 38.214 161.707 37.5728V31.2233H161.736L161.707 31.0188V29.4156L162.893 30.0405C164.397 30.8246 166.069 31.2306 167.765 31.2233C173.742 31.2233 178.606 26.2316 178.606 20.0942C178.606 13.9568 173.723 8.99479 167.746 8.99479ZM167.728 26.4138C166.448 26.4154 165.197 26.0336 164.137 25.3176C163.076 24.6016 162.254 23.5842 161.777 22.3966C161.452 21.6458 161.29 20.8344 161.301 20.0161C161.297 19.8565 161.309 19.6969 161.339 19.54C161.43 18.2977 161.883 17.1092 162.64 16.1202C163.397 15.1312 164.427 14.3846 165.602 13.9721C166.777 13.5596 168.047 13.4989 169.256 13.7976C170.465 14.0963 171.561 14.7414 172.409 15.6537C173.257 16.5661 173.821 17.7061 174.031 18.934C174.24 20.1619 174.087 21.4243 173.59 22.5666C173.094 23.7088 172.274 24.6812 171.233 25.3646C170.191 26.048 168.973 26.4126 167.728 26.4138Z'
        fill='#00D89E'
      />
      <path
        d='M94.6747 9.19829C93.4361 9.19635 92.2113 9.45728 91.0811 9.96382C89.9508 10.4704 88.9409 11.211 88.118 12.1368C86.5077 10.3328 84.8155 9.19829 81.7771 9.19829C80.1147 9.19829 78.0952 10.2584 77.3142 11.3222C77.2691 10.7424 77.0063 10.2011 76.5786 9.80708C76.1509 9.41309 75.5899 9.1956 75.0084 9.19829C74.4166 9.19829 73.849 9.43342 73.4306 9.85196C73.0121 10.2705 72.777 10.8382 72.777 11.4301V28.9867C72.777 29.5787 73.0121 30.1463 73.4306 30.5649C73.849 30.9834 74.4166 31.2185 75.0084 31.2185C75.6002 31.2185 76.1678 30.9834 76.5863 30.5649C77.0047 30.1463 77.2398 29.5787 77.2398 28.9867V17.9915C77.2398 16.8472 77.6944 15.7497 78.5034 14.9405C79.3124 14.1313 80.4098 13.6767 81.5539 13.6767C82.6981 13.6767 83.7954 14.1313 84.6044 14.9405C85.4135 15.7497 85.868 16.8472 85.868 17.9915V28.9867C85.868 29.5787 86.1031 30.1463 86.5216 30.5649C86.94 30.9834 87.5076 31.2185 88.0994 31.2185C88.6912 31.2185 89.2588 30.9834 89.6773 30.5649C90.0957 30.1463 90.3308 29.5787 90.3308 28.9867V17.9915C90.3308 16.8472 90.7854 15.7497 91.5944 14.9405C92.4035 14.1313 93.5008 13.6767 94.6449 13.6767C95.7891 13.6767 96.8864 14.1313 97.6954 14.9405C98.5045 15.7497 98.959 16.8472 98.959 17.9915V28.9867C98.959 29.5787 99.1941 30.1463 99.6126 30.5649C100.031 30.9834 100.599 31.2185 101.19 31.2185C101.782 31.2185 102.35 30.9834 102.768 30.5649C103.187 30.1463 103.422 29.5787 103.422 28.9867V17.9915C103.423 15.6668 102.503 13.4364 100.864 11.7884C99.2243 10.1404 96.999 9.20911 94.6747 9.19829Z'
        fill='#00D89E'
      />
      <path
        d='M32.3556 0.0056572H7.63146C5.60747 0.0056572 3.66638 0.809814 2.2352 2.24122C0.804026 3.67263 0 5.61404 0 7.63835L0 32.3665C0 34.3908 0.804026 36.3322 2.2352 37.7636C3.66638 39.195 5.60747 39.9992 7.63146 39.9992H32.3556C34.3779 39.9972 36.3167 39.1921 37.7457 37.7608C39.1747 36.3295 39.9769 34.3892 39.9759 32.3665V7.63835C39.9759 5.61597 39.1734 3.67625 37.7446 2.24516C36.3159 0.814076 34.3776 0.0086139 32.3556 0.0056572V0.0056572ZM8.08146 29.7628H6.99178L11.1571 14.2296C11.4231 13.2372 12.009 12.3604 12.8239 11.7349C13.6389 11.1095 14.6374 10.7704 15.6646 10.7703H16.7356L12.5629 26.3072C12.2979 27.2949 11.7158 28.1681 10.906 28.7924C10.0963 29.4168 9.10388 29.7577 8.08146 29.7628ZM17.0071 26.4523H15.9212L19.2683 14.2258C19.5362 13.2299 20.1262 12.3507 20.9464 11.7255C21.7666 11.1004 22.7706 10.7645 23.8018 10.7703H24.8692L21.522 22.993C21.2553 23.9856 20.6687 24.8626 19.8531 25.488C19.0376 26.1134 18.0385 26.4523 17.0109 26.4523H17.0071ZM28.8151 26.3072C28.5515 27.2921 27.9723 28.1634 27.1662 28.7876C26.3601 29.4117 25.3716 29.7543 24.3522 29.7628H23.2626L27.4093 14.2296C27.6753 13.2372 28.2612 12.3604 29.0761 11.7349C29.8911 11.1095 30.8895 10.7704 31.9167 10.7703H32.9841L28.8151 26.3072Z'
        fill='#00D89E'
      />
    </svg>
  );
};

export const IcSettings = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.84137 3.162L6.30056 3.34946C5.73347 3.54604 5.21031 3.83854 4.75067 4.20815L4.32319 4.5519L2.30451 3.86901L1.57593 5.13095L3.09017 6.46171L2.95043 7.04743C2.85799 7.43487 2.80881 7.84007 2.80881 8.25806C2.80881 8.54577 2.83211 8.8273 2.87672 9.10107L2.96579 9.64756L1.57592 10.869L2.3045 12.131L3.95735 11.5718L4.39549 11.9948C4.95617 12.5362 5.63721 12.9517 6.39322 13.1978L6.94955 13.3789L7.27377 15H8.73094L9.05516 13.3789L9.6115 13.1978C10.3675 12.9517 11.0486 12.5362 11.6092 11.9948L12.0474 11.5718L13.7002 12.131L14.4288 10.869L13.0389 9.64754L13.128 9.10105C13.1726 8.82729 13.1959 8.54576 13.1959 8.25806C13.1959 7.84008 13.1467 7.43489 13.0543 7.04745L12.9145 6.46173L14.4288 5.13097L13.7002 3.86902L11.6815 4.55192L11.2541 4.20816C10.7944 3.83855 10.2713 3.54604 9.70415 3.34946L9.16334 3.162L8.73094 1H7.27377L6.84137 3.162ZM9.55074 0H6.45397L5.97305 2.40462C5.29562 2.63944 4.67159 2.98854 4.12402 3.42886L1.84839 2.65904L0.300002 5.34092L1.97773 6.81536C1.86728 7.27826 1.80881 7.76133 1.80881 8.25806C1.80881 8.59986 1.83649 8.93519 1.88975 9.26193L0.299988 10.6591L1.84837 13.3409L3.7009 12.7143C4.36849 13.3588 5.18053 13.8547 6.08371 14.1487L6.45397 16H9.55074L9.921 14.1487C10.8242 13.8547 11.6362 13.3588 12.3038 12.7142L14.1564 13.3409L15.7047 10.659L14.115 9.2619C14.1682 8.93517 14.1959 8.59985 14.1959 8.25806C14.1959 7.76134 14.1374 7.27828 14.027 6.81538L15.7047 5.34094L14.1563 2.65905L11.8807 3.42887C11.3331 2.98855 10.7091 2.63944 10.0317 2.40462L9.55074 0ZM11.49 8.25995C11.49 10.1929 9.92297 11.76 7.98997 11.76C6.05697 11.76 4.48997 10.1929 4.48997 8.25995C4.48997 6.32695 6.05697 4.75995 7.98997 4.75995C9.92297 4.75995 11.49 6.32695 11.49 8.25995ZM7.98997 10.76C9.37068 10.76 10.49 9.64066 10.49 8.25995C10.49 6.87924 9.37068 5.75995 7.98997 5.75995C6.60926 5.75995 5.48997 6.87924 5.48997 8.25995C5.48997 9.64066 6.60926 10.76 7.98997 10.76Z'
        fill='#6B6B6B'
      ></path>
    </svg>
  );
};
